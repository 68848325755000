import React, { useContext, useState, useEffect } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";

import "./Home.css";

import Settings from "../../components/Settings/Settings";

import Card from "../../components/UI/Card/Card";
import Image from "../../components/UI/Image/Image";
import ScratchContainer from "../../components/UI/ScratchContainer/ScratchContainer";

import { items, itemsMobile, itemsTablet, gifs, faces } from "../../utils/data";
import { Translation } from "../../language";

const Home = () => {
    const { translate } = useContext(Translation);

    let content;
    let timeout = 0;

    let renderItems;
    if (isMobileOnly) {
        renderItems = itemsMobile;
    } else if (isTablet) {
        renderItems = itemsTablet;
    } else {
        renderItems = items;
    }

    const [currentZindex, setCurrentZindex] = useState(1);
    const [contentData, setContentData] = useState({
        renderItems: renderItems,
        gifs: gifs,
        faces: faces,
    });

    useEffect(() => {
        const themeSwitch = document.querySelector(
            '.themeSwitcher input[type="checkbox"]'
        );
        const currentTheme = localStorage.getItem("theme")
            ? localStorage.getItem("theme")
            : null;
        if (currentTheme) {
            document.documentElement.setAttribute("data-theme", currentTheme);
            if (currentTheme === "dark") {
                themeSwitch.checked = true;
            }
        }

        const languageSwitch = document.querySelector(
            '.languageSwitcher input[type="checkbox"]'
        );
        const currentLanguage = localStorage.getItem("language")
            ? localStorage.getItem("language")
            : null;
        if (currentLanguage) {
            if (currentLanguage === "de") {
                languageSwitch.checked = true;
            }
        }

        const currentColor = localStorage.getItem("color")
            ? localStorage.getItem("color")
            : null;
        if (currentColor) {
            document.getElementById(currentColor).classList.add("active");
            document.documentElement.setAttribute("data-color", currentColor);
        } else {
            document.getElementById("green").classList.add("active");
            document.documentElement.setAttribute("data-color", "green");
            localStorage.setItem("color", "green");
        }
    }, []);

    const onClickCloseButton = (event) => {
        let updatedData = { ...contentData };
        updatedData.renderItems[
            event.currentTarget.dataset.defaultindex
        ].isVisible = false;
        setContentData(updatedData);
    };

    content = contentData.renderItems.map((item, index) => {
        let headline = item.headline ? (
            <h1>{translate(item.headline)}</h1>
        ) : null;
        let text = item.text ? <p>{translate(item.text)}</p> : null;
        let link =
            item.linkText && item.linkHref ? (
                <a href={item.linkHref}>{item.linkText}</a>
            ) : null;

        timeout = timeout + 100;

        let image = item.image ? (
            <Image
                contentData={contentData}
                setContentData={(data) => setContentData(data)}
                currentZindex={currentZindex}
                setCurrentZindex={(i) => setCurrentZindex(i)}
                action={item.action}
                image={item.image}
                title={item.title}
                index={index}
            />
        ) : null;

        let settings = item.settings ? <Settings /> : null;

        let scratchContainer =
            item.action === "scratch" ? (
                <ScratchContainer
                    contentData={contentData}
                    title={item.title}
                />
            ) : null;

        return (
            <Card
                key={index}
                title={translate(item.title)}
                position={item.position}
                width={item.width}
                index={index}
                timeout={item.noTimeout ? 0 : timeout}
                isVisible={item.isVisible}
                settings={item.settings}
                currentZindex={currentZindex}
                setCurrentZindex={(index) => setCurrentZindex(index)}
                onClickCloseButton={(e) => onClickCloseButton(e)}
            >
                {headline}
                {text}
                {image}
                {link}
                {settings}
                {scratchContainer}
            </Card>
        );
    });

    return <div className="view viewHome">{content}</div>;
};

export default Home;
