import React, { useContext } from "react";
import { isMobileOnly } from "react-device-detect";

import "./Privacy.css";

import Card from "../../components/UI/Card/Card";

import { Translation } from "../../language";
import { privacy } from "../../utils/data";

const Privacy = () => {
    const { translate } = useContext(Translation);

    let timeout = 100;

    let updatedPrivacy = { ...privacy };
    if (isMobileOnly) updatedPrivacy.position = null;

    return (
        <div className="view viewPrivacy">
            <Card
                title={translate(updatedPrivacy.title)}
                position={updatedPrivacy.position}
                width={updatedPrivacy.width}
                timeout={timeout}
                isVisible={updatedPrivacy.isVisible}
            >
                <h1>{translate(updatedPrivacy.headline)}</h1>
                <div
                    dangerouslySetInnerHTML={{
                        __html: translate(updatedPrivacy.content),
                    }}
                ></div>
            </Card>
        </div>
    );
};

export default Privacy;
