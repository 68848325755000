import React, { useEffect, useState, useContext } from "react";

import { ScratchCard, SCRATCH_TYPE } from "scratchcard-js";

import "./ScratchContainer.css";

import { Translation } from "../../../language";

const ScratchContainer = (props) => {
    const { translate } = useContext(Translation);

    const [isReload, setIsReload] = useState(Date.now());

    useEffect(() => {
        const scContainer = document.getElementById("scratchContainer");
        scContainer.innerHTML = "";

        const sc = new ScratchCard("#scratchContainer", {
            scratchType: SCRATCH_TYPE.LINE,
            containerWidth: scContainer.offsetWidth,
            containerHeight: scContainer.offsetHeight,
            imageForwardSrc: "assets/scratch.jpg",
            htmlBackground: `<img src="assets/${
                props.contentData.gifs[
                    Math.floor(Math.random() * props.contentData.gifs.length)
                ]
            }" alt="${props.title}" />`,
            clearZoneRadius: 30,
            callback: function () {},
        });

        sc.init();
    }, [props.title, props.contentData.gifs, isReload]);

    const onClickReloadGif = () => {
        setIsReload(Date.now());
    };

    let image = (
        <React.Fragment>
            <div className="scWrapper">
                <div id="scratchContainer" className="sc__container"></div>
                <button onClick={() => onClickReloadGif()}>
                    {translate("lb_reload")}
                </button>
            </div>
        </React.Fragment>
    );

    return image;
};

export default ScratchContainer;
