import React from "react";
import { isMobileOnly } from "react-device-detect";
import "./Image.css";

import { rndIntFromInterval } from "../../../utils/helper";

const Image = (props) => {
    const onClickDoAction = (action) => {
        if (!action) return;

        if (action === "addGif") onClickAddGif();
        else if (action === "faceSwitch") onClickChangeFace();
    };

    const onClickAddGif = () => {
        let updatedData = { ...props.contentData };
        let gif =
            updatedData.gifs[
                Math.floor(Math.random() * updatedData.gifs.length)
            ];

        let newPosition = isMobileOnly
            ? null
            : {
                  left: rndIntFromInterval(5, 20),
                  top: rndIntFromInterval(5, 20),
              };

        let data = {
            isVisible: true,
            title: "lb_title_gif",
            image: { src: "assets/" + gif },
            width: 300,
            noTimeout: true,
            position: newPosition,
        };

        updatedData.renderItems.push(data);
        props.setCurrentZindex(props.currentZindex + 1);
        props.setContentData(updatedData);
    };

    const onClickChangeFace = () => {
        let updatedData = { ...props.contentData };
        let face =
            updatedData.faces[
                Math.floor(Math.random() * updatedData.faces.length)
            ];
        updatedData.renderItems[props.index].image.src = "assets/" + face;
        props.setContentData(updatedData);
    };

    let image = (
        <img
            src={props.image.src}
            alt={props.title}
            onClick={() => onClickDoAction(props.action)}
        />
    );

    //
    if (props.image.srcDarkmode) {
        image = (
            <div
                className="imageDarkmodeContainer"
                onClick={() => onClickDoAction(props.action)}
            >
                <img src={props.image.src} alt={props.title} />
                <img src={props.image.srcDarkmode} alt={props.title} />
            </div>
        );
    }

    return image;
};

export default Image;
