import React, { useContext } from "react";
import "./LanguageSwitcher.css";
import { Translation } from "../../../language";

const LanguageSwitcher = () => {
    const { dispatch } = useContext(Translation);

    const onChangeLanguage = (e) => {
        if (e.currentTarget.checked) {
            dispatch({ type: "setLanguage", payload: "de" });
            localStorage.setItem("language", "de");
        } else {
            dispatch({ type: "setLanguage", payload: "en" });
            localStorage.setItem("language", "en");
        }
    };

    return (
        <div className="languageSwitcher">
            <input
                type="checkbox"
                id="languageSwitch"
                onChange={(e) => onChangeLanguage(e)}
            />
            <label htmlFor="languageSwitch" />
        </div>
    );
};

export default LanguageSwitcher;
