import React from "react";
import "./ThemeSwitcher.css";

const ThemeSwitcher = () => {
    const onChangeTheme = (e) => {
        if (e.currentTarget.checked) {
            document.documentElement.setAttribute("data-theme", "dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.documentElement.setAttribute("data-theme", "light");
            localStorage.setItem("theme", "light");
        }
    };

    return (
        <div className="themeSwitcher">
            <input
                type="checkbox"
                id="themeSwitch"
                onChange={(e) => onChangeTheme(e)}
            />
            <label htmlFor="themeSwitch" />
        </div>
    );
};

export default ThemeSwitcher;
