import React, { useContext } from "react";
import "./Settings.css";

import ColorSwitcher from "../UI/ColorSwitcher/ColorSwitcher";
import LanguageSwitcher from "../UI/LanguageSwitcher/LanguageSwitcher";
import ThemeSwitcher from "../UI/ThemeSwitcher/ThemeSwitcher";

import { Translation } from "../../language";

const Settings = () => {
    const { translate } = useContext(Translation);

    const onClickReloadButton = () => {
        localStorage.setItem("theme", "light");
        localStorage.setItem("color", "green");
        window.location.reload();
    };

    return (
        <React.Fragment>
            <ThemeSwitcher />
            <LanguageSwitcher />
            <ColorSwitcher />
            <div className="resetButton">
                <button onClick={(e) => onClickReloadButton(e)}>
                    {translate("lb_reset")}
                </button>
            </div>
        </React.Fragment>
    );
};

export default Settings;
