import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";

import "./Card.css";

const Card = (props) => {
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsWaiting(false);
        }, props.timeout);
    }, [props.timeout]);

    useEffect(() => {
        const timer = setTimeout(() => {}, 3000);
        return () => clearTimeout(timer);
    }, []);

    const onHandleStart = (event) => {
        let index = props.currentZindex + 1;
        event.currentTarget.style.zIndex = index;
        props.setCurrentZindex(index);
    };

    let cardClasses;
    if (isWaiting) cardClasses = "card";
    else cardClasses = "card active";

    let left,
        top,
        right = "auto";
    if (props.position) {
        if (props.position.left) left = props.position.left + "%";
        if (props.position.right) right = props.position.right + "%";
        top = props.position.top + "%";
    }

    let closeButton;
    if (!props.settings) {
        closeButton = (
            <button
                className="closeButton"
                onClick={props.onClickCloseButton}
                data-defaultindex={props.index}
            >
                <svg viewBox="0 0 20 20">
                    <path d="M11.41,10l4.3-4.29a1,1,0,1,0-1.42-1.42L10,8.59,5.71,4.29A1,1,0,0,0,4.29,5.71L8.59,10l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L10,11.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
                </svg>
            </button>
        );
    }

    let display;
    if (props.isVisible) {
        display = "block";
    } else display = "none";

    let width, height, overflow;
    if (props.width) {
        if (props.width === "info") {
            width = "calc(100% - 4%)";
            height = "calc(100vh - 200px)";
            overflow = "auto";
        } else width = props.width + "px";
    }

    let returnContent = (
        <div
            className={cardClasses}
            style={{
                left: left,
                top: top,
                right: right,
                width: width,
                display: display,
            }}
        >
            <header className="handle">
                {closeButton}
                <p>{props.title}</p>
            </header>

            <div
                className="content"
                style={{ height: height, overflow: overflow }}
            >
                {props.children}
            </div>
        </div>
    );

    if (!props.position || props.width === "info") {
        return returnContent;
    } else {
        return (
            <Draggable
                handle=".handle"
                cancel=".closeButton"
                bounds="body"
                position={null}
                grid={[1, 1]}
                scale={1}
                onStart={(e) => onHandleStart(e)}
            >
                {returnContent}
            </Draggable>
        );
    }
};

export default Card;
