import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./containers/Home/Home";
import Legal from "./containers/Legal/Legal";
import Privacy from "./containers/Privacy/Privacy";

import Footer from "./components/Footer/Footer";

import { TranslationProvider } from "./language";

const App = () => {
    return (
        <React.Fragment>
            <div className="background"></div>
            <TranslationProvider>
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/legal" element={<Legal />} exact />
                        <Route path="/privacy" element={<Privacy />} exact />
                    </Routes>
                    <Footer />
                </main>
            </TranslationProvider>
        </React.Fragment>
    );
};

export default App;
