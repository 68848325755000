import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import "./Footer.css";

import { Translation } from "../../language";
import { navigation } from "../../utils/data";

const Footer = () => {
    const { translate } = useContext(Translation);

    let content = navigation.map((item, index) => {
        return (
            <NavLink key={index} to={item.path} title={translate(item.title)}>
                <p>{translate(item.title)}</p>
            </NavLink>
        );
    });

    return (
        <footer>
            <nav>{content}</nav>
        </footer>
    );
};

export default Footer;
