import React, { useContext } from "react";
import { isMobileOnly } from "react-device-detect";

import "./Legal.css";

import Card from "../../components/UI/Card/Card";

import { Translation } from "../../language";
import { legal } from "../../utils/data";

const Legal = () => {
    const { translate } = useContext(Translation);

    let timeout = 100;

    let updatedLegal = { ...legal };
    if (isMobileOnly) updatedLegal.position = null;

    return (
        <div className="view viewLegal">
            <Card
                title={translate(updatedLegal.title)}
                position={updatedLegal.position}
                width={updatedLegal.width}
                timeout={timeout}
                isVisible={updatedLegal.isVisible}
            >
                <h1>{translate(updatedLegal.headline)}</h1>
                <div
                    dangerouslySetInnerHTML={{
                        __html: translate(updatedLegal.content),
                    }}
                ></div>
            </Card>
        </div>
    );
};

export default Legal;
