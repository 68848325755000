import React, { useReducer } from "react";

import DE from "./de.json";
import EN from "./en.json";

const translations = {
    de: DE,
    en: EN,
};

//
let sessionUserLanguage = localStorage.getItem("language");
if (!sessionUserLanguage) {
    sessionUserLanguage = getLanguageInitial();
}

function getLanguageInitial() {
    var url = window.location.href;
    var language = getParameterByName("locale", url);
    if (language == null || language === "") {
        var userLang = navigator.language || navigator.userLanguage;
        language = userLang.split("-")[0];

        if (language !== "en" || language !== "de") language = "en";
    }
    return language;
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

//
const getTranslate = (langCode) => (key) => translations[langCode][key] || key;

const initialState = {
    langCode: sessionUserLanguage,
    translate: getTranslate(sessionUserLanguage),
};

export const Translation = React.createContext(initialState);

export const TranslationProvider = ({ children }) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case "setLanguage":
                return {
                    langCode: action.payload,
                    translate: getTranslate(action.payload),
                };
            default:
                return { ...initialState };
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Translation.Provider value={{ ...state, dispatch }}>
            {children}
        </Translation.Provider>
    );
};
