import React from "react";
import { colors } from "../../../utils/data";
import "./ColorSwitcher.css";

const ColorSwitcher = () => {
    const onClickChangeColor = (e, color) => {
        let elements = document.getElementsByClassName("colorSwitcherButton");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("active");
        }

        e.currentTarget.classList.add("active");
        document.documentElement.setAttribute("data-color", color);
        localStorage.setItem("color", color);
    };

    let buttons = colors.map((color, index) => {
        return (
            <button
                key={index}
                id={color}
                className="colorSwitcherButton"
                onClick={(e) => onClickChangeColor(e, color)}
            ></button>
        );
    });

    return <div className="colorSwitcher">{buttons}</div>;
};

export default ColorSwitcher;
