import { rndIntFromInterval } from "./helper";

export const navigation = [
    {
        path: "/",
        title: "lb_home",
    },
    {
        path: "/legal",
        title: "lb_legal",
    },
    {
        path: "/privacy",
        title: "lb_privacy",
    },
];

export const itemsMobile = [
    {
        isVisible: true,
        title: "lb_title_logo",
        image: { src: "assets/logo.svg", srcDarkmode: "assets/logo-white.svg" },
        width: 340,
    },
    {
        isVisible: true,
        title: "lb_title_about",
        headline: "lb_info_headline",
        text: "lb_info",
        width: 340,
    },
    {
        isVisible: true,
        title: "lb_title_face",
        image: { src: `assets/face-${rndIntFromInterval(1, 17)}.png` },
        action: "faceSwitch",
        width: 290,
    },
    {
        isVisible: true,
        title: "lb_title_scratch",
        action: "scratch",
        width: 290,
    },
    {
        isVisible: true,
        title: "lb_title_contact",
        linkText: "🤘 info@whoisflo.de",
        linkHref: "mailto:info@whoisflo.de",
        width: 340,
    },
    {
        isVisible: true,
        title: "lb_title_settings",
        settings: true,
        width: 240,
    },
];

export const itemsTablet = [
    {
        isVisible: true,
        title: "lb_title_logo",
        image: { src: "assets/logo.svg", srcDarkmode: "assets/logo-white.svg" },
        width: 300,
        position: { left: 5, top: 5 },
    },
    {
        isVisible: true,
        title: "lb_title_about",
        headline: "lb_info_headline",
        text: "lb_info",
        width: 550,
        position: { left: 5, top: 23 },
    },
    {
        isVisible: true,
        title: "lb_title_face",
        image: { src: `assets/face-${rndIntFromInterval(1, 17)}.png` },
        action: "faceSwitch",
        width: 300,
        position: { right: 6, top: 45 },
    },
    {
        isVisible: true,
        title: "lb_title_contact",
        linkText: "🤘 info@whoisflo.de",
        linkHref: "mailto:info@whoisflo.de",
        width: 300,
        position: { right: 6, top: 15 },
    },
    {
        isVisible: true,
        title: "lb_title_settings",
        settings: true,
        width: 240,
        position: { left: 5, top: 65 },
    },
    {
        isVisible: true,
        title: "lb_title_info",
        text: "lb_info_drag",
        width: 240,
        position: { right: 20, top: 75 },
    },
    {
        isVisible: true,
        title: "lb_title_scratch",
        width: 290,
        action: "scratch",
        position: {
            right: rndIntFromInterval(10, 20),
            top: rndIntFromInterval(70, 80),
        },
    },
];

export const items = [
    {
        isVisible: true,
        title: "lb_title_logo",
        image: { src: "assets/logo.svg", srcDarkmode: "assets/logo-white.svg" },
        width: 300,
        position: {
            left: rndIntFromInterval(2, 4),
            top: rndIntFromInterval(2, 8),
        },
    },
    {
        isVisible: true,
        title: "lb_title_about",
        headline: "lb_info_headline",
        text: "lb_info",
        width: 550,
        position: {
            left: rndIntFromInterval(20, 30),
            top: rndIntFromInterval(15, 25),
        },
    },
    {
        isVisible: true,
        title: "lb_title_face",
        image: { src: `assets/face-${rndIntFromInterval(1, 17)}.png` },
        action: "faceSwitch",
        width: 200,
        position: {
            right: rndIntFromInterval(5, 15),
            top: rndIntFromInterval(25, 35),
        },
    },
    {
        isVisible: true,
        title: "lb_title_contact",
        linkText: "🤘 info@whoisflo.de",
        linkHref: "mailto:info@whoisflo.de",
        width: 300,
        position: {
            right: rndIntFromInterval(2, 8),
            top: rndIntFromInterval(5, 15),
        },
    },
    {
        isVisible: true,
        title: "lb_title_settings",
        settings: true,
        width: 240,
        position: {
            left: rndIntFromInterval(2, 4),
            top: rndIntFromInterval(70, 70),
        },
    },
    {
        isVisible: true,
        title: "lb_title_info",
        text: "lb_info_drag",
        position: {
            left: rndIntFromInterval(2, 8),
            top: rndIntFromInterval(15, 25),
        },
    },
    {
        isVisible: true,
        title: "lb_title_scratch",
        width: 290,
        action: "scratch",
        position: {
            right: rndIntFromInterval(5, 15),
            top: rndIntFromInterval(50, 60),
        },
    },
];

export const legal = {
    isVisible: true,
    title: "lb_title_info",
    headline: "lb_legal",
    content: "lb_info_legal",
    width: "info",
    position: { left: 2, top: 2 },
};

export const privacy = {
    isVisible: true,
    title: "lb_title_info",
    headline: "lb_legal",
    content: "lb_info_legal",
    width: "info",
    position: { left: 2, top: 2 },
};

export const gifs = [
    "gif-1.gif",
    "gif-2.gif",
    "gif-3.gif",
    "gif-4.gif",
    "gif-5.gif",
    "gif-6.gif",
    "gif-7.gif",
    "gif-8.gif",
    "gif-9.gif",
    "gif-10.gif",
    "gif-11.gif",
    "gif-12.gif",
    "gif-13.gif",
    "gif-14.gif",
    "gif-15.gif",
    "gif-16.gif",
];

export const faces = [
    "face-1.png",
    "face-2.png",
    "face-3.png",
    "face-4.png",
    "face-5.png",
    "face-6.png",
    "face-7.png",
    "face-8.png",
    "face-9.png",
    "face-10.png",
    "face-11.png",
    "face-12.png",
    "face-13.png",
    "face-14.png",
    "face-15.png",
    "face-16.png",
    "face-17.png",
];

export const colors = ["black", "white", "green", "pink", "purple"];
